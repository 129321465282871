import React, { FC, useEffect } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import { LegalDocument, completeTextAction } from 'actions/completeTextAction'
import { goBackAction } from 'actions/route/routeAction'
import { isMamba, isMambaLite } from 'common/constants'
import { OsTypes } from 'common-constants/userAgent'
import { WrapperLayout } from 'components/layout/WrapperLayout'
import { RenderText } from 'components/page/Boarding/RenderText/RenderText'
import { PlainWrapper } from 'components/page/Legal/PlainWrapper'
import { InnerContent } from 'components/presentational/InnerContent'
import { CloseSvg } from 'components/presentational/svg/CloseSvg'
import { useHasUserRestrictions } from 'functions/hasUserRestrictions'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { AgreementTextNext } from './AgreementTextNext'
import ModalLayout from '../../../layout/ModalLayout/index'
import { Content } from '../../../presentational/modal'
import { ModalNavigation } from '../../../presentational/ModalNavigation'
import { Back2Svg } from '../../../presentational/svg/Back2Svg'

const AgreementIndex: FC<{
  fade?: boolean
  plain?: boolean
  standalone?: boolean
  /**
   * Зачем-то есть отдельные урлы для приложений
   * Андройд и айОС, хотя платформа должна была
   * и так определелится.
   * Пока остается для совместимости, в дальнейшем
   * должны брать прямо из CDN.
   */
  forcePlatform?: OsTypes
  redesign?: boolean
}> = ({ plain, standalone, forcePlatform, redesign }) => {
  const dispatch = useDispatch()
  const { text, textLoading, partnerId } = useShallowEqualSelector(
    ({
      textReducer: { text, textLoading },
      systemReducer: { partnerId },
      authorizationReducer: { profile },
    }) => ({
      text,
      textLoading,
      partnerId,
      isReal: profile.isReal,
    })
  )

  const target =
    isMamba(partnerId) || isMambaLite(partnerId) ? '_self' : '_blank'

  useEffect(() => {
    dispatch(completeTextAction(LegalDocument.agreement, forcePlatform))
  }, [forcePlatform, dispatch])

  const props = {
    standalone,
    textLoading,
    text,
  }

  if (redesign) {
    return <AgreementTextNext text={text} textLoading={textLoading} />
  }

  if (plain) {
    if (text && !textLoading) {
      const replacedText = text.replace('_target', '_blank')

      return (
        <WrapperLayout>
          <PlainWrapper text={replacedText} loading={false} />
        </WrapperLayout>
      )
    }

    return (
      <WrapperLayout>
        <PlainWrapper text={null!} loading={textLoading} />
      </WrapperLayout>
    )
  }

  if (!textLoading && text) {
    /**
     * https://redmine.mamba.ru/issues/118796
     * Оставляем везде одинаковые ссылки.
     * Для мамбы _self, для остальных _blank
     */
    const replaceText = text.replace('_target', target)

    return (
      <WrapperLayout>
        <Wrapper {...props} text={replaceText} />
      </WrapperLayout>
    )
  }

  return (
    <WrapperLayout>
      <Wrapper {...props} />
    </WrapperLayout>
  )
}

export default AgreementIndex

const Wrapper: FC<{
  standalone?: boolean
  text: string | null
  textLoading: boolean
}> = ({ standalone, text, textLoading }) => {
  const dispatch = useDispatch()

  const { authorized } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized } }) => ({
      authorized,
    })
  )

  const hasUserRestrictions = useHasUserRestrictions()

  /**
   * https://redmine.mamba.ru/issues/117099
   * Переход на vip внутри agreement происходит с перезагрузкой
   * из-за этого baseUrl заменяется на url модального окна.
   * Для обычного пользователя все работает хорошо
   */
  const actionBack =
    !authorized || hasUserRestrictions
      ? () => dispatch(goBackAction(true))
      : undefined

  return (
    <ModalLayout data-name={'modal-agreement'} onBackClick={actionBack}>
      <Content>
        <ModalNavigation
          icon={standalone ? <CloseSvg /> : <Back2Svg />}
          title={
            <FormattedMessage
              id="agreement.title"
              defaultMessage="Лицензионное соглашение"
            />
          }
          historyBack={true}
          onBackClick={actionBack}
        />
        <InnerContent>
          <RenderText text={text} plain={false} loading={textLoading} />
        </InnerContent>
      </Content>
    </ModalLayout>
  )
}
