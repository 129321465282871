import React, { ComponentProps, FC, ReactNode } from 'react'

import { css, cx } from '@linaria/core'
import { styled } from '@linaria/react'

import { Input } from 'components/designSystem/Input/Input'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const InputWithQuestionButton: FC<
  { elementRight: ReactNode } & ComponentProps<typeof Input>
> = ({ elementRight, ...inputProps }) => {
  const { rtl } = useShallowEqualSelector(({ systemReducer: { rtl } }) => ({
    rtl,
  }))

  return (
    <InputWrapper>
      <Input
        {...inputProps}
        inputStyle={rtl ? { paddingLeft: 40 } : { paddingRight: 40 }}
        inputCss={cx(inputProps.errorText && inputErrorCss)}
        elementRight={<ElementRight>{elementRight}</ElementRight>}
      />
    </InputWrapper>
  )
}

const InputWrapper = styled.div`
  position: relative;
`
const ElementRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 100%;
  position: absolute;
  padding: 0 12px;
  top: 50%;
  transform: translateY(-50%);
  html[dir='ltr'] & {
    right: 0;
  }
  html[dir='rtl'] & {
    left: 0;
  }
`
const inputErrorCss = css`
  padding-right: 40px;
`
