import React, { FC } from 'react'

import { styled } from '@linaria/react'

import { Loader } from 'components/designSystem/Loader/Loader'
import {
  formaDJRCyrillicMicroFontFamily,
  formaDJRCyrillicTextFontFamily,
} from 'components/designSystem/shared/fonts/formaDJRCyrillic/formaDJRCyrillicFontFamily'
import { PlainWrapper } from 'components/page/Legal/PlainWrapper'

export const ConfidentialityTextNext: FC<{
  text: string | null
  textLoading: boolean
}> = ({ text, textLoading }) => {
  if (!text || textLoading) {
    return <Loader />
  }

  return (
    <Text>
      <PlainWrapper text={text} loading={false} />
    </Text>
  )
}

const Text = styled.div`
  font-family: ${formaDJRCyrillicMicroFontFamily};
  text-align: left;

  .main-title {
    font-family: ${formaDJRCyrillicTextFontFamily};
    color: var(--Primary-Black-Warm, #2b2929);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 116.667% */
    letter-spacing: 0.48px;
    white-space: normal;
  }
  h2 {
    font-family: ${formaDJRCyrillicTextFontFamily};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    letter-spacing: 0.4px;
  }

  ol > li > p:first-of-type {
    display: inline;
  }
`
