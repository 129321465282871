import { definitions } from 'api/generated/uniweb'
import {
  appAgreementPath,
  appConfidentialityPath,
  searchSettingsPath,
  settingsPath,
} from 'components/paths'

export const checkIfShouldUseReferrer = (
  referrer: string,
  partner: definitions['PartnerShortInfo']
) =>
  referrer &&
  // Бекенд неправильно описал типы
  // https://redmine.mamba.ru/issues/120225
  referrer.startsWith(`https://${partner.url.web}`) &&
  referrer.indexOf(settingsPath) === -1 &&
  referrer.indexOf(appAgreementPath) === -1 && // не считаем реферером самого себя
  referrer.indexOf(appConfidentialityPath) === -1 && // не считаем реферером самого себя
  referrer.indexOf('development/paypal') === -1 && // заглушка для dev оплаты paypal
  referrer.indexOf(searchSettingsPath) === -1
