import React, { FC } from 'react'

import { styled } from '@linaria/react'

import { WithClassName } from 'common/types'

import { FooterInfo } from './FooterInfo'

export const WelcomePageFooter: FC<{} & WithClassName> = ({ className }) => (
  <Footer className={className}>
    <FooterInfo />
  </Footer>
)
const Footer = styled.footer`
  flex-basis: 50%;
  display: flex;
  align-items: end;
  justify-content: center;
  width: 100%;
  padding-top: var(--spacing-16px, 16px);
  flex-grow: 1;
`
