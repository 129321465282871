import React, { FC } from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { LOCALES } from 'common-constants/locale'
import { Typography } from 'components/designSystem/Typography/Typography'
import { useBaseUrl } from 'components/system/baseUrl/BaseUrlContext'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { changeLanguagePath } from './paths'
import { NavigationRouterLink } from 'components/navigation/NavigationRouterLink'

export const ChangeLanguageButton: FC = () => {
  const { locale } = useShallowEqualSelector(
    ({ systemReducer: { locale } }) => ({
      locale,
    })
  )

  const baseUrl = useBaseUrl()

  const languageName = LOCALES.find(({ code }) => code === locale)?.name

  return (
    <ChangeLanguageWrapper>
      <NavigationRouterLink
        to={mergeAllUrls(baseUrl, changeLanguagePath)}
        replaceIfNotBaseUrl={true}
        data-name="change-language-action"
      >
        <ChangeLanguageText fontSize={11}>
          <Language>
            <FormattedMessage id="app.language" defaultMessage="Язык" />
          </Language>
          : {languageName}
        </ChangeLanguageText>
      </NavigationRouterLink>
    </ChangeLanguageWrapper>
  )
}

const ChangeLanguageWrapper = styled.div``

const ChangeLanguageText = styled(Typography)`
  color: var(--accent-primary-default, #2e2a29);
`
const Language = styled.span`
  color: var(--foreground-muted, #99908e);
`
