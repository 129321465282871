import React, { FC, useRef, useEffect } from 'react'

import styled, { css } from 'styled-components'

import { media } from 'components/presentational'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'

export const RenderText: FC<{
  text: string | null
  /**
   * Зачем было переусложнять CSS?
   */
  plain?: boolean
  loading: boolean
}> = ({ text, plain, loading }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (wrapperRef.current && !loading) {
      window.scroll(0, 1)
    }
  }, [loading])

  if (loading) {
    return <DefaultLoader />
  }

  if (plain) {
    return (
      <PlainText ref={wrapperRef} dangerouslySetInnerHTML={{ __html: text! }} />
    )
  }

  return <Text ref={wrapperRef} dangerouslySetInnerHTML={{ __html: text! }} />
}

const plainCss = css`
  word-wrap: break-word;

  h1,
  h2 {
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .fake-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 44px;
    min-height: 44px;
    box-sizing: border-box;
    margin: 0 -40px -64px -40px;
    position: relative;
    top: -64px;
    pointer-events: none;
  }

  .fake-separator {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  .main-title {
    font-size: 20px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-weight: bold;
    pointer-events: auto;
  }

  .title {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  @media (max-width: 600px) {
    .fake-wrapper {
      padding: 8px 10px;
      top: -44px;
      margin: 0 -20px -44px -20px;
    }

    .main-title {
      font-size: 18px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  th,
  td {
    ${(props) => props.theme.paddingRight}: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    vertical-align: top;
  }

  th {
    ${media.tablet`
      display: none;
  `};
    text-align: ${(props) => props.theme.textAlignLeft};
  }

  td {
    ${media.tablet`
      &:first-child {
        word-break: normal;
        font-weight: bold;
      }

      display: block;
      word-break: break-all;
    `};
  }

  td p {
    margin-top: 0;
    margin-bottom: 15px;
  }

  p {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  li p:first-child {
    display: inline;
  }
`

export const PlainText = styled.div`
  ${plainCss}

  ol, ul {
    padding: 0;
    list-style-position: inside;
  }

  li::marker {
    font-weight: bold;
  }
`

export const Text = styled.div`
  ${plainCss}

  h1:first-child {
    display: none;
  }

  ul {
    margin-top: 0;
    ${(props) => props.theme.paddingLeft}: 14px;

    @media screen and (orientation: landscape) {
      ${(props) => props.theme.paddingLeft}: 18px;
    }
  }

  ul li {
    ${(props) => props.theme.paddingLeft}: 6px;
    line-height: 21px;
    margin-bottom: 10px;
  }

  ol {
    margin-top: 0;
    padding: 0;
  }

  ol li {
    line-height: 21px;
    margin-bottom: 10px;
    list-style-type: none;
    position: relative;
  }

  ol li li,
  ol li li li {
    ${(props) => props.theme.paddingLeft}: 14px;
  }

  ol {
    counter-reset: list1;
  }

  ol.order-list-exceptions {
    counter-reset: list1;
  }

  ol.order-list-exceptions li:before {
    counter-increment: list1; /* Увеличиваем значение счетчика */
    content: counter(list1) '. '; /* Выводим число */
    font-weight: bold;
  }

  ol > li:before {
    counter-increment: list1;
    content: counter(list1) '. ';
    font-weight: bold;
  }

  ol ol {
    margin-bottom: 20px;
    margin-top: 10px;
    counter-reset: list2;
  }

  ol ol > li:before {
    counter-increment: list2;
    content: counter(list1) '.' counter(list2) '. ';
    font-weight: bold;
  }

  ol ol ol {
    margin-bottom: 20px;
    margin-top: 10px;
    counter-reset: list3;
  }

  ol ol ol > li:before {
    counter-increment: list3;
    content: counter(list1) '.' counter(list2) '.' counter(list3) '. ';
    font-weight: bold;
  }

  ul.list-un-order {
    margin-top: 10px;
    ${(props) => props.theme.marginLeft}: 14px;
    list-style-type: disc;
  }

  ul.list-un-order li {
    list-style-type: disc;
    padding: 0;
  }

  .bracket-type-list {
    counter-reset: list;
    padding-left: 0;
  }

  .bracket-type-list li {
    list-style: none;
    padding-left: 0;
  }

  .bracket-type-list > li:before {
    content: counter(list) ') ';
    counter-increment: list;
  }

  .upperCase {
    text-transform: upperCase;
  }
`
