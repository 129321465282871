import React, { forwardRef } from 'react'

import {
  RouterLink,
  RouterLinkProps,
} from 'components/designSystem/Link/RouterLink'
import { useModalOpened } from 'hooks/useModalOpened'

/**
 * Ссылка для одноуровневых, простых боттомшитов:
 *
 * 1. если открываем из базового урла, то push, чтобы работала кнопка назад
 * 2. если открыт уже какой-то другой боттомшит, то заменяем
 *
 * https://youtrack.mamba.ru/issue/M-9948
 */
export const NavigationRouterLink = forwardRef<
  HTMLAnchorElement,
  {
    replaceIfNotBaseUrl?: boolean
  } & RouterLinkProps
>(({ children, ...props }, ref) => {
  const modalOpened = useModalOpened()

  return (
    <RouterLink {...props} ref={ref} replace={modalOpened}>
      {children}
    </RouterLink>
  )
})
