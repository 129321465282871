import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { checkIfShouldUseReferrer } from 'components/layout/ModalLayout/checkIfShouldUseReferrer'
import { push } from 'functions/router'

export const modalOverlayClickAction = () => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const {
    systemReducer: { referrer, touch, baseUrl, partner },
  } = getState()

  if (!partner || !referrer) {
    return
  }

  const shouldUseReferrer = checkIfShouldUseReferrer(referrer, partner)

  if (baseUrl !== '/') {
    // TODO временное решение. Если пользователь был на старом таче перешел на uniweb,
    // и там вызвал витрину то нужно вернуть его также на uniweb.
    return dispatch(push(baseUrl))
  } else {
    if (touch && shouldUseReferrer) {
      return (window.location.href = referrer)
    } else if (baseUrl) {
      return dispatch(push(baseUrl))
    } else if (shouldUseReferrer) {
      return (window.location.href = referrer)
    }
  }
  window.location.href = '/'
}
