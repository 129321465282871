import React, { FC } from 'react'

import { styled } from '@linaria/react'

import { breakpoints } from 'components/designSystem/shared/breakpoints'

import { ChangeLanguageButton } from './ChangeLanguageButton'
import { SeoLinks } from './SeoLinks'

export const HeaderDesktop: FC<{ visible: boolean }> = ({ visible }) => {
  return (
    <DesktopHeaderInner
      style={{
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? 'all' : 'none',
      }}
    >
      <SeoLinks />
      <ChangeLanguageButton />
    </DesktopHeaderInner>
  )
}

const DesktopHeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 25px;
  left: 40px;
  right: 40px;
  transition: opacity 300ms;

  @media screen and (max-width: ${breakpoints.mobile - 1}px) {
    display: none;
  }
`
