import React, { FC } from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { Anchor } from 'components/designSystem/Link/Anchor'
import { Typography } from 'components/designSystem/Typography/Typography'
import { NavigationRouterLink } from 'components/navigation/NavigationRouterLink'
import {
  cityListPath,
  shortcutListPath,
} from 'components/page/Boarding/boarding.paths'
import {
  useShowCitiesLink,
  useShowCountries,
} from 'components/page/Boarding/footer/footer.hooks'
import { NAVIGATION_LINKS } from 'components/presentational/Navigation/NavigationLinks'
import { useBaseUrl } from 'components/system/baseUrl/BaseUrlContext'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useShowLinkBlog } from 'hooks/useShowLinkBlog'

const { label, to } = NAVIGATION_LINKS['blog']

export const SeoLinks: FC = () => {
  const baseUrl = useBaseUrl()
  const isShowCityLink = useShowCitiesLink()
  const isShowCountries = useShowCountries()
  const hasLinkBlog = useShowLinkBlog()

  return (
    <SeoLinksWrapper>
      {isShowCountries && (
        <Typography fontSize={11}>
          <NavigationRouterLink
            to={mergeAllUrls(baseUrl, shortcutListPath)}
            replaceIfNotBaseUrl={true}
            black
            data-name="countries-action"
          >
            <FormattedMessage
              id="boarding.footer.countries"
              defaultMessage="Страны"
            />
          </NavigationRouterLink>
        </Typography>
      )}
      {isShowCityLink && (
        <Typography fontSize={11}>
          <NavigationRouterLink
            to={mergeAllUrls(baseUrl, cityListPath)}
            replaceIfNotBaseUrl={true}
            black
            data-name="cities-action"
          >
            <FormattedMessage
              id="boarding.footer.cities"
              defaultMessage="Города"
            />
          </NavigationRouterLink>
        </Typography>
      )}
      {hasLinkBlog && (
        <Typography fontSize={11}>
          <Anchor href={to} data-name="blog-action" black>
            {label}
          </Anchor>
        </Typography>
      )}
    </SeoLinksWrapper>
  )
}

const SeoLinksWrapper = styled.div`
  display: flex;
  gap: 32px;
`
