import React, { FC, useEffect, useRef } from 'react'

import styled from 'styled-components'

import { formaDJRCyrillicTextFontFamily } from 'components/designSystem/shared/fonts/formaDJRCyrillic/formaDJRCyrillicFontFamily'
import { media } from 'components/presentational'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'

const Wrapper = styled.div`
  margin: 8px;
  word-wrap: break-word;

  table {
    table-layout: fixed;
    word-break: break-word;
  }

  h1,
  h2 {
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .main-title {
    font-family: ${formaDJRCyrillicTextFontFamily};
    color: var(--Primary-Black-Warm, #2b2929);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 116.667% */
    letter-spacing: 0.48px;
    text-align: center;
  }

  .title {
    font-family: ${formaDJRCyrillicTextFontFamily};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    letter-spacing: 0.4px;
  }

  ul {
    margin-top: 0;
    ${(props) => props.theme.paddingLeft}: 14px;

    @media screen and (orientation: landscape) {
      ${(props) => props.theme.paddingLeft}: 18px;
    }
  }

  ul li {
    ${(props) => props.theme.paddingLeft}: 6px;
    line-height: 21px;
    margin-bottom: 10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  th,
  td {
    ${(props) => props.theme.paddingRight}: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    vertical-align: top;
  }

  th {
    ${media.tablet`
      display: none;
  `};
    text-align: ${(props) => props.theme.textAlignLeft};
  }

  td {
    ${media.tablet`
      &:first-child {
        word-break: normal;
        font-weight: bold;
      }

      display: block;
      word-break: break-all;
    `};
  }

  td p {
    margin-top: 0;
    margin-bottom: 15px;
  }

  p {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  ol {
    margin-top: 0;
    padding: 0;
  }

  ol li {
    line-height: 21px;
    margin-bottom: 10px;
    list-style-type: none;
    position: relative;
  }

  ol li li,
  ol li li li {
    ${(props) => props.theme.paddingLeft}: 14px;
  }

  ol {
    counter-reset: list1;
  }

  ol li:before {
    counter-increment: list1; /* Увеличиваем значение счетчика */
    content: counter(list1) '. '; /* Выводим число */
    font-weight: bold;
  }

  .conditions > ol > li:before {
    font-size: 20px;
  }

  ol ol {
    margin-bottom: 20px;
    margin-top: 10px;
    counter-reset: list2;
  }

  ol ol li:before {
    counter-increment: list2; /* Увеличиваем значение счетчика вложенного списка */
    content: counter(list1) '.' counter(list2) '. '; /* Выводим число */
    font-weight: bold;
  }

  ol ol ol {
    margin-bottom: 20px;
    margin-top: 10px;
    counter-reset: list3;
  }

  ol ol ol li:before {
    counter-increment: list3; /* Увеличиваем значение счетчика вложенного списка */
    content: counter(list1) '.' counter(list2) '.' counter(list3) '. '; /* Выводим число */
    font-weight: bold;
  }

  .bracket-type-list {
    counter-reset: list;
    padding-left: 0;
  }

  .bracket-type-list li {
    list-style: none;
    padding-left: 0;
  }

  .bracket-type-list > li:before {
    content: counter(list) ') ';
    counter-increment: list;
  }

  .upperCase {
    text-transform: upperCase;
  }
`
// Отменяем сброс стилей src/common/components/designSystem/shared/globalCss.ts
const WrapperForPlainHtml = styled.div`
  padding: 20px 8px;

  * {
    margin: revert;
    padding: revert;
  }

  li {
    list-style: revert;
  }
`

export const PlainWrapper: FC<{
  text: string
  plain?: boolean
  loading: boolean
}> = ({ text, plain, loading }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (wrapperRef.current && !loading) {
      window.scroll(0, 1)
    }
  }, [loading])

  if (loading) {
    return <DefaultLoader />
  }

  if (plain) {
    return (
      <WrapperForPlainHtml
        ref={wrapperRef}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    )
  }

  return <Wrapper ref={wrapperRef} dangerouslySetInnerHTML={{ __html: text }} />
}
